import { ProductType } from "@graphql/types";

function createALSProductIndex<T extends Partial<Record<ProductType, string>>>(
	input: T,
) {
	return input;
}

export { ProductType };

export const ALSProduct = createALSProductIndex({
	[ProductType.CreditTool]: "AVA Credit",
	[ProductType.TradeTool]: "AVA Trade",
	[ProductType.IdTool]: "AVA ID",
	[ProductType.LenderTool]: "AVA Lender", // double check name for Lender Tool
	[ProductType.TradePro]: "AVA Trade Pro", // double check name for Trade Pro
});

export type ActiveALSProduct = "GLOBAL" | keyof typeof ALSProduct;

export const ALSProductDisplay: ActiveALSProduct[] = [
	"GLOBAL",
	ProductType.CreditTool,
	ProductType.TradeTool,
	ProductType.IdTool,
];

type AnyObject = Record<string, unknown>;
type NoExtension = Record<never, never>;

export type ProductDetails<T extends AnyObject = NoExtension> = T & {
	name: ActiveALSProduct;
	title: string;
	enabled?: Maybe<boolean>;
};

export type ProductsDetailIndex<T extends AnyObject = NoExtension> = Record<
	ActiveALSProduct,
	ProductDetails<T>
>;

export type ProductDetailCollection<
	T extends AnyObject = NoExtension,
	TIndex extends string = "index",
	TOrdered extends string = "ordered",
> = {
	[K in TIndex]: ProductsDetailIndex<T>;
} & {
	[K in TOrdered]: ProductDetails<T>[];
};
