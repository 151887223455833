import { useMemo } from "react";
import { useAuth } from "./auth";
import {
	ALSProductDisplay,
	ALSProduct,
	ProductDetailCollection,
	ProductDetails,
	ProductsDetailIndex,
} from "~/model/products";
import { FetchResult, useMutation } from "@apollo/client";
import { captureError } from "@utils/sentry";

import { CreateField, UpdateField } from "./query/fields";
import { CreateCompanyProduct, UpdateCompanyProduct, GetProducts } from "./query/products";
import { CreateCompanyProductIntegration, UpdateCompanyProductIntegration } from "./query/integrations";

import {
	CompanyPatch,
	CreateCompanyProductMutation,
	CreateCompanyProductMutationVariables,
	CreateFieldMutation,
	CreateFieldMutationVariables,
	UpdateCompanyProductMutation,
	UpdateCompanyProductMutationVariables,
	UpdateCompanyMutation,
	UpdateCompanyMutationVariables,
	UpdateFieldMutation,
	UpdateFieldMutationVariables,
	WidgetPatch,
	CreateCompanyProductIntegrationMutation,
	CreateCompanyProductIntegrationMutationVariables,
	UpdateCompanyProductIntegrationMutation,
	UpdateCompanyProductIntegrationMutationVariables,
} from "./graphql/types";
import { GetCompanies, UpdateCompany, UpdateWidget } from "./query/company";
import { ProductType as GeneratedProductType } from "@graphql/types";
import type { CamelCase, ScreamingSnakeCase } from "type-fest";

export enum NewProductType {
	IncomeTool = "INCOME_TOOL",
	DriveTool = "DRIVE_TOOL",
}

export type ProductType = `${GeneratedProductType}` | `${NewProductType}`;

export const ProductType: {
	[K in ProductType as Capitalize<CamelCase<K>>]: ScreamingSnakeCase<K>;
} = {
	...GeneratedProductType,
	...NewProductType,
};

export const useProductList = <R extends Record<string, unknown>>(
	extend?: (detail: ProductDetails) => ProductDetails<R>,
): ProductDetailCollection<R> => {
	const { selectedCompany } = useAuth();

	const products = useMemo(() => {
		const keys = ALSProductDisplay;

		return keys.reduce(
			(acc, productType) => {
				const productNode = selectedCompany?.companyProducts.nodes.find(
					(node) => node.product?.name === productType,
				);
				const isProductAvailable =
					productNode && productNode.product?.enabled;
				const isEnabled =
					!selectedCompany || productType === "GLOBAL"
						? true
						: !!isProductAvailable;

				const productDetailsBase: ProductDetails = {
					name: productType,
					title:
						productType === "GLOBAL"
							? "All Leads"
							: ALSProduct[productType],
					enabled: isEnabled,
				};

				const productDetails = extend
					? extend(productDetailsBase)
					: productDetailsBase;

				Object.assign(acc.index, {
					[productType]: productDetails,
				});
				acc.ordered.push(productDetails as ProductDetails<R>);

				return acc;
			},
			{
				ordered: [],
				index: {} as ProductsDetailIndex<R>,
			} as ProductDetailCollection<R>,
		);
	}, [extend, selectedCompany]);

	return products;
};

export interface IUpdateCompany {
	updateCompany: (
		id: number,
		patch: CompanyPatch,
	) => Promise<FetchResult<UpdateCompanyMutation>>;
}

export interface IUpdateWidget {
	updateWidget: (id: string, patch: WidgetPatch) => Promise<FetchResult<any>>;
}

export const useUpdateCompany = (): IUpdateCompany => {
	const [doUpdateCompany] = useMutation<
		UpdateCompanyMutation,
		UpdateCompanyMutationVariables
	>(UpdateCompany, {
		refetchQueries: [GetCompanies],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const actions: IUpdateCompany = useMemo(
		() => ({
			updateCompany: (id: number, patch: CompanyPatch) => {
				return doUpdateCompany({
					variables: {
						input: {
							id,
							patch,
						},
					},
				});
			},
		}),
		[doUpdateCompany],
	);

	return actions;
};

export const useUpdateWidget = (): IUpdateWidget => {
	const [doUpdateWidget] = useMutation<any, any>(UpdateWidget, {
		refetchQueries: [GetCompanies],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const actions: IUpdateWidget = useMemo(
		() => ({
			updateWidget: (id: string, patch: WidgetPatch) =>
				doUpdateWidget({
					variables: {
						input: {
							id,
							patch,
						},
					},
				}),
		}),
		[doUpdateWidget],
	);

	return actions;
};

export interface ICompanyProductsActions {
	createField: (input: ProductFieldInput) => Promise<FetchResult<any>>
	updateField: (id: number, value: string) => Promise<FetchResult<any>>
	createCompanyProduct: (
		companyId: number,
		productId: number,
	) => Promise<FetchResult<CreateCompanyProductMutation>>;
	updateCompanyProduct: (
		id: number,
		enabled: boolean,
	) => Promise<FetchResult<UpdateCompanyProductMutation>>;
	createCompanyProductIntegration: (
		companyProductIntegration: { companyProductId: number, companyIntegrationId: number }
	) => Promise<FetchResult<CreateCompanyProductIntegrationMutation>>;
	updateCompanyProductIntegration: (
		id: number,
		enabled: boolean,
	) => Promise<FetchResult<UpdateCompanyProductIntegrationMutation>>;
}

export interface ProductFieldInput {
	value: string;
	companyId: number;
	companyProductId: number;
	config: number;
}

export const useEditCompanyProducts = (): ICompanyProductsActions => {
	const [doCreateField] = useMutation<
		CreateFieldMutation,
		CreateFieldMutationVariables
	>(CreateField, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateField] = useMutation<
		UpdateFieldMutation,
		UpdateFieldMutationVariables
	>(UpdateField, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doCreateCompanyProduct] = useMutation<
		CreateCompanyProductMutation,
		CreateCompanyProductMutationVariables
	>(CreateCompanyProduct, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateCompanyProduct] = useMutation<
		UpdateCompanyProductMutation,
		UpdateCompanyProductMutationVariables
	>(UpdateCompanyProduct, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doCreateCompanyProductIntegration] = useMutation<
		CreateCompanyProductIntegrationMutation,
		CreateCompanyProductIntegrationMutationVariables
	>(CreateCompanyProductIntegration, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateCompanyProductIntegration] = useMutation<
		UpdateCompanyProductIntegrationMutation,
		UpdateCompanyProductIntegrationMutationVariables
	>(UpdateCompanyProductIntegration, {
		refetchQueries: [GetProducts],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const actions: ICompanyProductsActions = useMemo(
		() => ({
			createField: (field: ProductFieldInput) =>
				doCreateField({
					variables: {
						input: { field },
					},
				}),
			updateField: (id: number, value: string) =>
				doUpdateField({
					variables: {
						input: {
							id, patch: { value }
						},
					},
				}),
			createCompanyProduct: (companyId: number, productId: number) =>
				doCreateCompanyProduct({
					variables: {
						input: {
							companyProduct: {
								companyId,
								productId,
							},
						},
					},
				}),
			updateCompanyProduct: (id: number, enabled: boolean) =>
				doUpdateCompanyProduct({
					variables: {
						input: {
							id,
							patch: { enabled }
						},
					},
				}),
			createCompanyProductIntegration: (companyProductIntegration: { companyProductId: number, companyIntegrationId: number }) =>
				doCreateCompanyProductIntegration({
					variables: {
						input: {
							companyProductIntegration,
						},
					},
				}),
			updateCompanyProductIntegration: (id: number, enabled: boolean) =>
				doUpdateCompanyProductIntegration({
					variables: {
						input: {
							id,
							patch: { enabled }
						},
					},
				}),
		}),
		[doCreateCompanyProduct, doUpdateCompanyProduct],
	);

	return actions;
};
