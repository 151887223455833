import React, { createContext, useContext } from "react";

import { FetchResult, useQuery } from "@apollo/client";

import { GetProducts } from "@api/query/products";

import { useFeedbackContext } from "~/components/Feedback";

import {
	GetProductsQuery,
	GetProductsQueryVariables,
	UpdateWidgetSettingMutation,
} from "@api/graphql/types";
import { ProductFieldInput, useEditCompanyProducts } from "@api/products";
import { getErrorMessage } from "@utils/errors";

interface IProductsContextType {
	products?: NonNullable<GetProductsQuery["products"]>["nodes"];
	loading: boolean;
	tradeTemplate?: NonNullable<UpdateWidgetSettingMutation["updateWidgetSetting"]>["widgetSetting"];
	createField: (v: ProductFieldInput) => void;
	updateField: (id: number, v: string) => void;
	createCompanyProductIntegration: (props: { companyProductId: number, companyIntegrationId: number }) => void;
	updateCompanyProductIntegration: (id: number, v: boolean) => void;
}

const defaultContext: IProductsContextType = {
	products: undefined,
	loading: false,
	tradeTemplate: undefined,
	createField: (v: ProductFieldInput) => {
		/* dummy Fn */
	},
	updateField: (id: number, v: string) => {
		/* dummy Fn */
	},
	createCompanyProductIntegration: (props: { companyProductId: number, companyIntegrationId: number }) => {
		/* dummy Fn */
	},
	updateCompanyProductIntegration: (id: number, v: boolean) => {
		/* dummy Fn */
	},
};

const ProductsContext = createContext<IProductsContextType>(defaultContext);

export const useProductsContext = () => {
	const context = useContext(ProductsContext);
	if (!context) {
		throw new Error(
			"You cannot use the Products Context outside of its Provider!",
		);
	}
	return context;
};

const order = [
	"AVA Credit",
	"AVA Trade",
	"AVA Buy",
	"AVA Trade Pro",
	"AVA Id",
	"AVA PreQual",
	"AVA Lender Connect",
	"AVA Reviews",
];

const useProductsContextValue = ({ companyId }: { companyId: string }) => {
	const { handleOpenFeedback } = useFeedbackContext();

	const actions = useEditCompanyProducts();

	const create = (func: (...args: any[]) => Promise<FetchResult<any>>) => async (...args: any[]) => {
		try {
			const result = await func(...args);
			if (result.errors) {
				throw result.errors;
			}

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			return result;
		} catch (error) {
			console.error(error);
			handleOpenFeedback({
				message: getErrorMessage(error),
				severity: "error",
			});
		}
		return false;
	};

	const createField = create(actions.createField);
	const updateField = create(actions.updateField);
	const createCompanyProductIntegration = create(actions.createCompanyProductIntegration);
	const updateCompanyProductIntegration = create(actions.updateCompanyProductIntegration);

	const { data, loading } = useQuery<
		GetProductsQuery,
		GetProductsQueryVariables
	>(GetProducts, {
		variables: {
			companyId: Number(companyId),
		},
		onError(err) {
			console.error(err);
		},
	});

	const products = [...(data?.products?.nodes || [])];
	products?.sort((a, b) => {
		if (order.indexOf(a.displayName) < order.indexOf(b.displayName)) {
			return -1;
		}

		if (order.indexOf(a.displayName) > order.indexOf(b.displayName)) {
			return 1;
		}

		return 0;
	});

	return {
		products,
		loading,
		createField,
		updateField,
		createCompanyProductIntegration,
		updateCompanyProductIntegration,
	};
};

export const ProductsProvider: React.FC<any> = (props) => {
	const { children } = props;
	const contextValue = useProductsContextValue(children?.props?.params);

	return (
		<ProductsContext.Provider value={contextValue}>
			{children}
		</ProductsContext.Provider>
	);
};
