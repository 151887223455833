import React, { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { Input } from "~/components/Input";
import { useProductsContext } from "../context";

export const useStyles = makeStyles((theme) => ({
	input: {
		padding: 10,
	},
}));

interface FieldConfigInputProps {
	companyId: number;
	companyProductId: number;
	config: any;
}

export const FieldConfigInput = ({ companyId, companyProductId, config }: FieldConfigInputProps) => {
	const { createField, updateField } = useProductsContext();
	const field = useMemo(() => {
		return config.fieldsByConfig?.nodes?.[0];
	}, [config]);

	const onSubmit = useCallback(
		debounce(async (value: string) => {
			if (field?.id) {
				const result = await updateField(field?.id, value);
				console.log("updateField", result);
			} else {
				const result = await createField({ value, companyId, config: config.id, companyProductId });
				console.log("createField", result);
			}
		}, 400),
		[field],
	);

	const [value, setValue] = useState("");
	const styles = useStyles();

	const update = useCallback(
		(v: string) => {
			setValue(v);
			onSubmit(v);
		},
		[setValue, onSubmit],
	);

	useEffect(() => {
		setValue(field?.value || "");
	}, [companyId]);

	return (
		<Input
			type="text"
			label={config.label}
			placeholder={config.placeholder || config.label}
			helperText={config.helper}
			value={value}
			update={update}
			classes={{ formControl: { container: styles.input } }}
		// disabled={!config.enabled}
		/>
	);
};
