import React, { Fragment } from "react";

import Box from "@material-ui/core/Box";
import { Typography } from "~/components/Typography";

import { customStyles } from "./styles";

import type { IProductCardProps } from "./types";

const ProductCard = ({
	header,
	displayName,
	logo,
	inputs,
}: IProductCardProps) => {
	const styles = customStyles();

	return (
		<Fragment>
			<Box className={styles.description}>
				{logo}
				<Typography variant="h6" className={styles.displayName}>
					{displayName}
				</Typography>
			</Box>
			<Box className={styles.content}>
				{header}
				{!!header && !!inputs && <hr className={styles.separator} />}
				{!!inputs && <Box className={styles.inputs}>
					{inputs}
				</Box>}
			</Box>
		</Fragment>
	);
};

export default ProductCard;
