import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "~/components/Typography";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useEditCompanyProducts } from "@api/products";
import { useFeedbackContext } from "~/components/Feedback";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	labelWithLogo: {
		display: "flex",
		alignItems: "center",
		gap: 8,
	},

	toggle: {
		display: "flex",
		justifyContent: "space-between",
		marginRight: 0,
		marginLeft: 8,
	},
}));


export const LabelWithLogo: React.FC<any> = ({ logo, title }) => {
	const styles = useStyles();
	return (
		<div className={styles.labelWithLogo}>
			{logo && logo}
			<Typography variant="subtitle1">{`Enable ${title}`}</Typography>
		</div>
	);
};

export const ToolSwitch: React.FC<any> = ({ product, companyId }) => {
	const { handleOpenFeedback } = useFeedbackContext();
	const id = product.companyProducts?.nodes?.[0]?.id;
	const enabled = !!product.companyProducts?.nodes?.[0]?.enabled;

	const setError = (message: string) =>
		handleOpenFeedback({ message, severity: "error" });
	const setSuccess = (message: string) =>
		handleOpenFeedback({ message, severity: "success" });

	const styles = useStyles();

	const { updateCompanyProduct, createCompanyProduct } =
		useEditCompanyProducts();

	const toggleTool = async () => {
		try {
			const productId = product.id;
			const name = product?.displayName;
			if (id) {
				const result = await updateCompanyProduct(id, !enabled);
				if ((result.errors as any)?.message) {
					setError((result.errors as any)?.message);
					return;
				}
				if (enabled) {
					handleOpenFeedback({
						message: `${name} has been disabled.`,
						severity: "warning",
					});
				} else {
					setSuccess(`${name} has been enabled.`);
				}
				return !enabled;
			} else {
				const result = await createCompanyProduct(Number(companyId), productId);
				if ((result.errors as any)?.message) {
					setError((result.errors as any)?.message);
					return;
				}
				setSuccess(`${name} has been enabled.`);
				return true;
			}
		} catch (error) {
			console.error(error);
		}

		return false;
	};

	const Label = withStyles((theme) => ({
		label: theme.typography.inputLabel,
	}))(FormControlLabel);

	return (
		<Label
			className={styles.toggle}
			key={product.name}
			control={<Switch checked={enabled} onClick={toggleTool} />}
			labelPlacement="start"
			label={<LabelWithLogo title={product.displayName} />}
		/>
	);
};
