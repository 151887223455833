import React, { Fragment, useRef, useState } from "react";
import _ from "lodash";

import { useCompanyInformation } from "@api/companies";

import { useStyles } from "./styles";

import { useProductsContext } from "../context";

import { BaseForm } from "~/components/Base/BaseForm";
import { ProductLogo } from "~/components/Products";
import ProductCard from "../components/ProductCard";
import ProductLabel from "../../../_lib/components/ProductLabel";

import Box from "@material-ui/core/Box";

import type { IEditProductAccessFormProps } from "@admin/types";
import { Spinner } from "~/components/Spinner";

import {
	FieldConfigInputProps,
	FieldConfigSelect,
} from "../components/FieldConfigSelect";
import { FieldConfigInput } from "../components/FieldConfigInput";
import ProductIntegrations from "../components/ProductIntegrations";
import { ToolSwitch } from "../components/ToolSwitch";

import { GetProductsQuery } from "@api/graphql/types";
import CompanyNameTitle from "~/components/CompanyNameTitle";

const Input = (props: FieldConfigInputProps) => {
	if (props.config.type === "OPTION") {
		return (
			<>
				<FieldConfigSelect {...props} />
			</>
		);
	}

	return (
		<>
			<FieldConfigInput {...props} />
		</>
	);
};

interface Props {
	companyId: number;
	product: NN<NN<GetProductsQuery["products"]>["nodes"]>[0];
}

const Integrations = ({ product }: Props) => {
	if (!product.productIntegrations?.nodes?.length) {
		return null;
	}

	return (
		<>
			<ProductIntegrations product={product} />
		</>
	);
};

const Inputs = ({ companyId, product }: Props) => {
	if (!product.fieldConfigs?.nodes?.length) {
		return null;
	}

	const companyProductId = product.companyProducts?.nodes?.[0]?.id;

	return (
		<>
			{product.fieldConfigs?.nodes?.map((config) => (
				<Input
					key={config.id}
					companyId={companyId}
					companyProductId={companyProductId}
					config={config}
				/>
			))}
		</>
	);
};

const Content = ({ companyId, product }: Props) => {
	if (!product.companyProducts.nodes?.[0]?.enabled) {
		return null;
	}
	return (
		<>
			<Integrations companyId={companyId} product={product} />
			<Inputs companyId={companyId} product={product} />
		</>
	);
};

export const EditProductAccessForm: React.FC<IEditProductAccessFormProps> = ({
	companyId,
}) => {
	const styles = useStyles();
	const refs = useRef<HTMLDivElement[]>([]);

	const { products } = useProductsContext();
	const { loading: companyLoading, company } = useCompanyInformation(
		Number(companyId),
	);
	const [error, _setError] = useState("");

	return (
		<Fragment>
			<CompanyNameTitle companyName={company?.name || ""} />
			<Box className={styles.root}>
				<Box className={styles.productNav}>
					{products?.map((p, index) => {
						return (
							<ProductLabel
								key={p.displayName}
								onClick={() =>
									refs?.current[index]?.scrollIntoView({
										behavior: "smooth",
									})
								}
								displayName={p.displayName}
								productName={p.name}
							/>
						);
					})}
				</Box>
				<Box className={styles.form}>
					{companyLoading && <Spinner className={styles.spinner} />}
					{!companyLoading && (
						<BaseForm error={error} className={styles.formContent}>
							{products?.map((p, index) => {
								return (
									<div
										style={{ width: "100%" }}
										ref={(el: HTMLDivElement) =>
											(refs.current[index] = el)
										}
										key={p.name}
									>
										<ProductCard
											logo={
												<ProductLogo
													productName={p.name}
												/>
											}
											displayName={p.displayName}
											inputs={
												<Content
													companyId={companyId}
													product={p}
												/>
											}
											header={
												<ToolSwitch
													companyId={Number(
														companyId,
													)}
													product={p}
												/>
											}
										/>
									</div>
								);
							})}
						</BaseForm>
					)}
				</Box>
			</Box>
		</Fragment>
	);
};
