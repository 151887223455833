import React, { useCallback } from "react";

import { useProductsContext } from "../../context";

import { makeStyles } from "@material-ui/core/styles";

import { GetProductsQuery } from "@api/graphql/types";

import Select from "~/components/ControlledSelect";
import { SelectOption } from "~/components/ControlledSelect/types";

export const useStyles = makeStyles((theme) => ({
	select: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
	},
}));

export interface ProductIntegrationsProps {
	product: NN<NN<GetProductsQuery["products"]>["nodes"]>[0];
}

interface Option extends SelectOption {
	companyIntegrationId: number;
	id?: number;
}

export const ProductIntegrations = ({ product }: ProductIntegrationsProps) => {
	const { createCompanyProductIntegration, updateCompanyProductIntegration } = useProductsContext();
	const companyProduct = product.companyProducts.nodes?.[0];
	const companyProductId = companyProduct.id;

	const options: Option[] = product.productIntegrations.nodes
		.map((pi) => {
			const companyIntegration = pi.integration?.companyIntegrations.nodes?.[0];
			const companyProductIntegration = companyIntegration?.companyProductIntegrations.nodes?.[0];
			return ({
				display: pi.integration?.displayName || "",
				companyIntegrationId: companyIntegration?.id!,
				id: companyProductIntegration?.id,
				value: !!companyProductIntegration?.enabled,
				disabled: !companyIntegration?.enabled,
			});
		});

	const value = options.filter((option) => option.value);

	const onSubmit = useCallback(
		async (selected: Option[]) => {
			const option = value.filter(v => !selected.find((x) => x.companyIntegrationId === v.companyIntegrationId)).concat(
				selected.filter(v => !value.find((x) => x.companyIntegrationId === v.companyIntegrationId))
			)[0];

			if (option.id) {
				const result = await updateCompanyProductIntegration(option.id!, !option.value);
				console.log("updateCompanyProductIntegration", result);
			} else {
				const result = await createCompanyProductIntegration({ companyProductId, companyIntegrationId: option.companyIntegrationId! });
				console.log("createCompanyProductIntegration", result);
			}
		},
		[value],
	);

	const styles = useStyles();

	return (
		<Select
			className={styles.select}
			value={value}
			defaultValue={[]}
			label="Integrations"
			helper="Choose your integration provider"
			setValue={onSubmit}
			options={options}
			multiple
		/>
	);
};

export default ProductIntegrations;